/* Profile.css */

.profile-container {
    max-width: 95%;
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* animation: fadeIn 1.5s ease-in-out; */
    background-color: #f0f5ff;
}

.profile-header {
    display: flex;
    align-items: center;
    /* animation: scaleIn 1.5s ease-in-out; */
    background-color: #4004A417;
    padding: 10px;
    border-radius: 10px;
}

.view-profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 20px;
    /* animation: scaleIn 1.5s ease-in-out; */
    border: 0.25rem solid #4004a4;
}

.profile-details {
    flex-grow: 1;
    margin-top: -25px;
}

.profile-details>h2 {
    color: #4004a4;
    font-weight: 700;
    /* font-size: 18px; */
    font-family: "Poppins",
        Sans-serif;
    margin-bottom: 0px;
}

.profile-section {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    /* animation: fadeInUp 1.5s ease-in-out; */
    box-shadow: inset 0 0 0 10px rgba(255, 255, 255, 0.2);
    background: linear-gradient(to right, #F6FFDE, #E3F2C1 30%, #C9DBB2 70%, #AAC8A7);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Profile.css */

/* ... (existing styles) */

.book-appointment {
    margin-top: 10px;
    text-align: center;
}

.book-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #d079e9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

/* .book-button:hover {
    background-color: #0056b3;
} */

.read-more-link {
    /* Adjust the color as needed */
    text-decoration: underline;
    cursor: pointer;
}

.about-me-content {
    -webkit-line-clamp: 8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    color: rgba(0, 0, 0, 0.5);
    font-family: Quicksand,
        sans-serif;
}

.about-me-content.expanded {
    -webkit-line-clamp: unset;
}

.read-more-link {
    float: right;
    font-size: 0.9rem;
    font-weight: 700;
    color: #4004a4;
    text-decoration: underline;
    text-underline-offset: 2px;
}

.read-more-link:hover {
    color: #0095f6;
    /* Change color on hover */
}

/* PsychiatryOffer.css */

.psychiatry-offer-container {
    margin-bottom: 20px;
    background-color: #4004A417;
    padding: 10px;
    border-radius: 10px;
}

.psychiatry-categories {
    display: flex;
    flex-wrap: wrap;
}


.psychiatry-categories .MuiChip-avatar {
    width: 35px !important;
    height: 35px !important;
}

.psychiatry-categories .MuiChip-root {
    font-size: 1.2rem !important;
    height: 50px !important;
    width: 100%;
    justify-content: left;
    border-radius: 26px;
    border: none !important;
}

.psychiatry-category {
    text-align: center;
    margin: 0px 30px 35px 0px;
}

.psychiatry-category-img {
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    background-color: #ccccff;
    color: #1976d2;
    border-radius: 8px;
    transition: transform 0.3s ease;
    box-shadow: 5px 5px 10px rgb(45, 45, 45);
    width: 173px;
}

.psychiatry-category-img img {
    width: fit-content;
    margin-bottom: 8px;
}

.psychiatry-languages-img {
    margin-bottom: 15px;
    transition: transform 0.3s ease;
    display: flex;
    height: fit-content;
}

.psychiatry-languages-text {
    display: block;
    font-size: 1.67em;
    margin-block-start: 0.4em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 800;
    margin-left: 10px;
    color: #4004a4;
    font-family: "Poppins",
        Sans-serif !important;
}

.psychiatry-category-img img {
    margin-bottom: 8px;
}

.language {
    padding: 10px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    background-color: #add;
    color: #1976d2;
    border-radius: 8px;
    transition: transform 0.3s ease;
    box-shadow: 5px 5px 10px rgb(45, 45, 45);
    width: fit-content;
}

hr {
    margin: 20px 0px 35px 0px;
}

@media screen and (min-width: 360px) and (max-width: 667px) {
    .profile-header {
        display: inline-block;
    }

    .view-profile-image {
        margin-left: 30px;
    }

    .profile-details {
        display: inline;
    }

    .profile-details h2 {
        margin-bottom: 5px;
    }

    .psychiatry-languages-text {
        font-size: 1.09em;
    }
}