.doctor-role {
  font-size: smaller;
  font-style: italic;
  text-transform: capitalize;
}
.appointment-option-icon {
  margin-left: 12px;
}
.option-title {
  display: flex;
  padding-left: 16px !important;
  font-weight: bold !important;
}
.appointment-type-img {
  width: 50% !important;
  margin-left: 25%;
  margin-bottom: 16px;
}
