.kwtVtz * {
    box-sizing: border-box;
}

/* Styles for mobile devices */

/* Add styles here for screens with a maximum width of 767px */
@media screen and (max-width: 767px) {
    @media screen and (orientation: landscape) {
        .stack-list {
            margin-left: 0px;
        }
    }
    @media screen and (orientation: portrait) {
        .stack-list {
            margin-left: -10px;
        }
    }
    /* Styles for mobile devices go here */
    .provider-section {
        height: 100%;
        min-width: 98% !important;
        max-width: 98% !important;
        background-color: rgb(180 200 220);
    }

    .booking-container {
        width: 98% !important;
    }

    .book-appointment-page {
        flex-direction: column !important;
        overflow-y: scroll;
    }

    .session-type-btns {
        width: 100% !important;
    }

    .section-divider {
        width: 80% !important;
    }

    .clinic-cards-section {
        margin-left: 0px !important;
    }

    .auto-complete {
        width: 280px !important;
    }

    .hide-in-mobile {
        display: none !important;
    }

    .session-package-element {
        padding: 1rem 0rem !important;
    }
}

* {
    font-family: Quicksand, sans-serif !important;
}

body::-webkit-scrollbar {
    display: none !important;
}

.book-appointment-page {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    cursor: unset;
    opacity: 1;
    top: 64px;
    bottom: 0px;
    height: 90vh;
}

.provider-section {
    display: block;
    min-width: 19rem;
    max-width: 19rem;
    background-color: #1976d259;
    padding: 0.8rem 2rem;
    flex: 1 1 0%;
    position: relative;
    margin-top: 0px;
    box-sizing: border-box;
}

.provider-card {
    display: flex;
    width: 100%;
    border-radius: 0.75rem;
    background-color: rgb(255, 255, 255);
    min-height: 5.5rem;
    cursor: pointer;
    margin-bottom: 2rem;
    margin: 1rem 0rem
}

.provider-photo {
    width: 4rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    object-fit: cover
}

.details-div {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0px;
    margin: 0px;
    padding: 1rem;
    width: 62%;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.name-sec {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.1rem;
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 1rem;
    font-weight: 700;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.details-sub-section {
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 0.75rem;
    font-weight: unset;
    color: rgb(165, 165, 165);
    margin: 1rem 0px 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.dot {
    height: 4px;
    width: 4px;
    background-color: rgb(78, 97, 55);
    border-radius: 50%;
    display: inline-block;
    margin: 0px 5px;
    vertical-align: middle;
}

.provider-experience {
    left: unset;
    top: unset;
    position: inherit;
    display: block;
    font-size: 0.75rem;
    font-weight: unset;
    color: rgb(76, 76, 76);
    margin: 0.5rem 0px 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 0.6;
    float: none;
}

.provider-card-right-arrow {
    -webkit-box-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    /* align-items: stretch; */
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.booking-stepper {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 2rem 0px 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.css-8t49rw-MuiStepConnector-line {
    min-height: 13px !important;
    border-left-width: 2px !important;
    border-color: #4004a4 !important;
    margin-left: -4px;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active,
.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
    cursor: pointer !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed:hover,
.css-1hv8oq8-MuiStepLabel-label.Mui-active:hover {
    color: #4004a4 !important;
    font-weight: 800;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: #4004a4 !important;
    font-weight: 700;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    width: 0.8em !important;
    height: 0.8em !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #4004a4 !important;
    cursor: pointer !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: rgb(76, 76, 76) !important;
    cursor: pointer !important;
}

.booking-container {
    padding: 2rem 0px 5rem;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
}

.heading-one {
    font-size: 1.56rem;
    line-height: 32px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.sub-heading {
    font-size: 1rem;
    line-height: 24px;
    opacity: 1;
    font-weight: 500;
    color: rgb(76, 76, 76);
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.booking-container-head {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 1rem 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.section {
    padding: 2rem 0px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.section-title {
    line-height: 1;
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 1rem;
    font-weight: 700;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.section-body {
    -webkit-box-pack: center;
    justify-content: center;
    margin: 2rem 0px;
}

.toggle-buttons {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.session-type-btns {
    -webkit-box-pack: center;
    flex: 1 1 0%;
    width: 11rem;
    height: 3rem;
    background-color: rgb(255, 255, 255) !important;
    box-sizing: border-box !important;
    border: 2px solid rgb(210, 210, 210) !important;
    transition: all 0.2s ease 0s;
    font-weight: 700 !important;
}

.session-type-btns.Mui-selected {
    background-color: #d079e9 !important;
    border: 0px !important;
    color: rgb(255, 255, 255) !important;
}

.section-divider {
    border-width: 4px 0px 0px;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image: initial;
    margin: auto;
    border-top-style: solid;
    border-top-color: rgb(239, 239, 239);
    width: 30rem;
}

.clinic-card {
    display: block;
    margin: 1rem 0rem;
    text-align: center;
    border-radius: 0.75rem;
    background-color: rgb(255, 255, 255);
    min-height: 5.5rem;
    cursor: pointer;
    margin-bottom: 2rem;
    margin: 1rem 0rem;
    border: 2px solid #d079e9;
    min-width: 230px !important;
}

.clinic-cards-section {
    margin: 1.6rem 0rem 0rem 2rem;
}

.clinic-photo {
    width: 10rem;
    margin-top: -1.7rem;
    border: 2px solid #d079e9;
    border-radius: 10px;
}

.clinic-details {
    justify-content: center;
    gap: 0px;
    margin: 0px;
    padding: 1rem;
    cursor: unset;
    opacity: 1;
}

.clinic-selected,
.duration-selected {
    background-color: #1976d259 !important;
}

.clinic-selected,
.name-sec,
.details-sub-section,
.dot,
.clinic-details {
    color: #4004a4 !important;
}

.duration-section {
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 2rem 0px;
    column-gap: 1.5rem !important;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    gap: 0px;
    padding: 0px;
    width: auto;
    cursor: unset;
    opacity: 1;
}

.duration-element {
    text-align: center;
    position: relative;
    flex-direction: column !important;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid #d079e9;
    z-index: 0;
    pointer-events: all;
    background-color: rgb(255, 255, 255);
    width: 15.75rem;
    min-height: 6.75rem;
    border-radius: .75rem;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
}

.duration-mins {
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 1.25rem;
    font-weight: 700;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.duration-min-text {
    left: unset;
    top: unset;
    position: inherit;
    display: block;
    font-size: 0.875rem;
    font-weight: unset;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.session-package {
    flex-direction: column !important;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    max-width: 30rem;
    margin: 1rem auto;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    gap: 0px;
    padding: 0px;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.session-package-element {
    flex-direction: column !important;
    border-bottom: 1px solid rgb(239, 239, 239);
    padding: 1rem 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.session-package-slot {
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.session-package-details {
    padding: 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    gap: 0px;
    margin: 0px;
    width: auto;
    position: relative;
    opacity: 1;
}

.session-package-price {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 0px 0.5rem;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.session-package-radio {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
    min-width: 1.5rem;
}

.session-plans {
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 0.875rem;
    font-weight: 600;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.package-name {
    left: unset;
    top: unset;
    position: inherit;
    display: block;
    font-size: 0.75rem;
    font-weight: 100;
    color: rgb(76, 76, 76);
    margin: 0px 0px 4px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.css-xelq0e-MuiPickerStaticWrapper-content {
    min-width: 100% !important;
}

.css-1vcokmn-MuiCalendarOrClockPicker-root {
    padding-right: 0px 15px;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #d079e9 !important;
}

.css-1vw5i9y-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #d079e9 !important;
}

.css-1vw5i9y-MuiButtonBase-root-MuiTab-root {
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.confirm-session-details {
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #1976d259;
    border: 2px solid #d079e9;
}

.confirm-session-details-divisions {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.confirm-session-details-division-one {
    column-gap: 1.5rem !important;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
    box-sizing: border-box;
}

.confirm-session-details>div>h5 {
    font-size: 0.8rem;
    line-height: 20px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
    letter-spacing: unset;
}

.date-and-time {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0.25rem 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.date {
    font-size: 0.8rem;
    line-height: 20px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
    letter-spacing: unset;
}

.time {
    font-size: 0.8rem;
    line-height: 20px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
    letter-spacing: unset;
}

.time-duration {
    font-size: 0.8rem;
    line-height: 20px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
    letter-spacing: unset;
}

.payment-details {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(210, 210, 210);
    box-sizing: border-box;
    margin-top: 1.5rem;
}

.payment-details-body {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0.75rem 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.payment-details-title {
    font-size: 0.89rem;
    line-height: 24px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.payment-details-preview {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 0.5rem 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.payment-details-preview-divisions {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.payment-details-preview-package {
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 0.75rem;
    font-weight: unset;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.payment-details-preview-price {
    left: unset;
    top: unset;
    position: inherit;
    display: inline;
    font-size: 0.75rem;
    font-weight: unset;
    color: rgb(165, 165, 165);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.final-amount-row {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    width: auto;
    position: relative;
    cursor: unset;
    opacity: 1;
}

.final-amount-label {
    font-size: 1rem;
    line-height: 24px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.final-amount {
    font-size: 1rem;
    line-height: 24px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.css-1012bww-MuiChip-root {
    font-size: 0.75rem;
    font-weight: 700;
    color: rgb(76, 76, 76);
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    opacity: 1;
}

.signup-section {
    padding: 1.5rem 0px;
    background-color: rgb(250, 250, 250);
    width: 30rem;
    border-radius: 12px;
    text-align: center;
    z-index: 5;
    max-height: 50rem;
}

.signup-header {
    border-bottom: 1px solid rgb(239, 239, 239);
    padding-bottom: 0.8rem;
}

.signup-head-one {
    font-size: 1.56rem;
    line-height: 32px;
    opacity: 1;
    font-weight: 700;
    color: rgb(76, 76, 76);
    text-align: center;
    margin: 0px 0px 0.3rem;
    padding: 0px;
}

.signup-login-link {
    position: inherit;
    display: inline;
    font-size: 0.875rem;
    font-weight: unset;
    color: rgb(76, 76, 76);
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    text-underline-offset: 0px;
    word-break: normal;
    border-radius: 0px;
    white-space: normal;
    cursor: unset;
    opacity: 1;
    float: none;
}

.login-link {
    color: #d079e9;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
    font-weight: 700;
}

.email-signup-head {
    font-size: 0.8rem;
    line-height: 20px;
    opacity: 1;
    font-weight: 500;
    color: rgb(76, 76, 76);
    text-align: center;
    margin: 0px;
    padding: 0px;
    letter-spacing: unset;
    margin-top: 1rem;
}

.signup-form {
    padding-top: 1rem;
}

.signup-form-element {
    padding: 0px 1rem;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 0px 1rem;
}

.signup-form-footer {
    padding: 0px 1rem;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 0px 1rem;
}

@keyframes glowing {
    /* 0% {
        background-color: #4004a4;
        box-shadow: 0 0 3px #1976d259;
    } */

    50% {
        background-color: #d079e9;
        box-shadow: 0 0 5px #deaceb;
    }

    /* 100% {
        background-color: #1976d259;
        box-shadow: 0 0 3px #4004a4;
    } */
}

.create-appointment-btn:hover {
    animation: glowing 1300ms infinite;
}

.create-appointment-btn,
.create-account-btn {
    display: inline;
    background-color: #d079e9;
    color: rgb(255, 255, 255);
    padding: 1rem 2rem;
    line-height: 1;
    width: fit-content;
    min-width: 12.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 2rem;
    border: 1px solid #d079e9;
    overflow: hidden;
    letter-spacing: 0.08rem;
    cursor: pointer;
    text-transform: uppercase;
}

.create-account-btn:disabled,
.create-appointment-btn:disabled {
    display: inline;
    padding: 1rem 2rem;
    line-height: 1;
    width: fit-content;
    min-width: 12.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 2rem;
    overflow: hidden;
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    cursor: not-allowed;
    opacity: 0.4;
    background-color: rgb(76, 76, 76);
    border: 1px solid rgb(76, 76, 76);
    color: rgb(255, 255, 255);
}

/* Styles for mobile devices */

@media screen and (min-width: 750px) {
    @media screen and (orientation: landscape) {
        .auto-complete {
            width: 400px !important;
        }
        
        .hide-in-bigscreens {
            display: none !important;
        }
    }

    @media screen and (orientation: portrait) {
        .auto-complete {
            width: 350px !important;
        }
        
        .hide-in-bigscreens {
            display: none !important;
        }
    }
}

/* Add styles here for screens with a maximum width of 767px */
@media screen and (min-width: 1181px) {

    /* Styles for mobile devices go here */
    .hide-scroll-bar::-webkit-scrollbar {
        display: block;
    }

    .auto-complete {
        width: 500px !important;
    }

    .right-section {
        overflow: auto;
        width: calc(100% - 16rem);
    }

    .confirm-body {
        width: 50%;
    }
    
    .hide-in-bigscreens {
        display: none !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 667px) {
    .duration-section {
        display: block;
        margin: -10px 0 -10px 0;
    }

    .duration-element {
        margin-bottom: 15px;
    }

    .clinic-card {
        margin-left: 2rem;
    }

    .signup-section {
        width: 22rem !important;
    }

    .home-appt-heading {
        font-size: 1rem !important;
    }

    .filter-heading {
        font-size: 0.88rem !important;
        margin-top: 5px !important;
    }

    .home-box {
        margin-top: 10px;
        border-radius: 10px 10px !important;
    }
    .MuiGrid-container .css-mhc70k-MuiGrid-root{
        margin-left: 1.6rem;
    }
}

.MuiChip-colorSuccess {
    background-color: #d079e9 !important;
}

.css-3eghsz-PrivatePickersYear-button.Mui-selected,
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #d079e9 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #d079e9 !important;
}

.css-vgb7rt {
    min-height: 13px !important;
    border-color: #4004a4 !important;
    border-left-width: 2px !important;
}