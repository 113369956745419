.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .provider-list {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@media screen and (min-width:1024px) {
  .provider-list {
    grid-template-columns: repeat(auto-fill, 47.5%);
  }
}

.provider-list {
  display: grid;
  width: 110%;
  max-width: 108%;
  margin: auto;
}

.profile-card {
  position: relative;
  width: 95%;
  /* min-height: 16rem; */
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  border: 1px solid rgba(24, 42, 136, 0.2);
  border-radius: 0.75rem;
  overflow: hidden;
  padding-top: 1rem;
  margin: 1rem 1rem 0rem -0.25rem;
  background-color: rgb(255, 255, 255);
  max-height: fit-content !important;
}

.provider-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 75%; */
  margin-top: 0.3rem;
  /* max-height: 13.8rem; */
  padding-bottom: 0.6rem;
}

.provider-image-container {
  position: relative;
  width: 35%;
  /* height: 100%; */
  margin: 0px 1rem 0rem;
  /* min-height: 12rem; */
  /* float: left; */
}

.profile-image {
  border-radius: 0.6rem;
  /* height: 100%; */
  /* max-height: 12rem; */
  width: 100%;
  object-fit: cover;
  border: 1px solid #4004a4;
  cursor: pointer;
}


.profile-image:hover::after {
  content: "View Profile";
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profile-image:hover {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  background-color: #bdbaba;
}

.profile-view {
  /* position: absolute; */
  width: 100%;
  bottom: 0px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #4004a4;
  padding: 0.4rem 0px;
  font-size: 0.65rem;
  text-align: center;
  letter-spacing: 1.4px;
  color: rgb(255, 255, 255) !important;
  border-radius: 0.6rem;
  white-space: nowrap;
  cursor: pointer;
  min-height: fit-content;
}

.provider-info {
  flex-wrap: wrap;
  width: 65%;
  flex-direction: column;
  gap: 0.3rem;
  -webkit-box-pack: center;
  justify-content: center;
  /* height: fit-content !important; */
}

.provider-book-container {
  padding: 1rem 0.6rem;
  background-color: rgb(247, 247, 247);
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
}

.provider-book-button {
  display: inline;
  background-color: #d079e9;
  color: #fff;
  line-height: 3;
  /* min-width: 12.5rem; */
  font-weight: 700;
  border-radius: 2rem;
  border: 1px solid #d079e9;
  overflow: hidden;
  letter-spacing: 0.08rem;
  cursor: pointer;
  text-transform: uppercase;
  float: right;
  font-size: 0.8rem;
  padding: 0px 20px;
}

.profile-name {
  font-weight: 700;
  font-size: 1.25rem;
  color: rgb(78, 97, 55);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: unset;
  position: inherit;
  display: inline;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  line-height: 1.5;
  text-align: left;
  letter-spacing: normal;
  text-underline-offset: 0px;
  word-break: normal;
  border-radius: 0px;
  white-space: normal;
  cursor: unset;
  opacity: 1;
  float: none;
  text-transform: capitalize;
}

.experience {
  font-size: 0.8rem;
  top: unset;
  position: inherit;
  display: inline;
  font-weight: unset;
  color: rgb(76, 76, 76);
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  line-height: 1.5;
  text-align: left;
  letter-spacing: normal;
  text-underline-offset: 0px;
  word-break: normal;
  border-radius: 0px;
  white-space: normal;
  cursor: unset;
  opacity: 1;
  float: none;
}

.title-text {
  font-size: 0.8rem;
  color: rgb(121, 121, 121);
  position: inherit;
  display: inline;
  font-weight: unset;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  line-height: 1.5;
  text-align: left;
  letter-spacing: normal;
  text-underline-offset: 0px;
  word-break: normal;
  border-radius: 0px;
  white-space: normal;
  cursor: unset;
  opacity: 1;
  float: none;
}

.fee {
  color: rgb(76, 76, 76);
  font-size: 0.8rem;
  font-weight: 700;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.expertise-details {
  /* display: flex;
  flex-direction: row; */
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  height: fit-content;
  position: relative;
}

.expertise-list {
  /* display: flex;
  flex-direction: row; */
  /* overflow-x: auto; */
  gap: 0.5rem;
  overflow-wrap: break-word;
  overflow: auto;
  max-height: 65px;
  min-height: fit-content;
}

.expertise {
  color: rgb(76, 76, 76);
  background-color: #ddd;
  border-radius: 4px;
  font-size: 0.7em;
  width: fit-content;
  white-space: nowrap;
  padding: 0.4rem 0.7rem;
  /* display: flex; */
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 35px;
  /* border: 1px solid dodgerblue; */
  margin: 2px !important;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: block;
}

.languages-container {
  overflow: hidden;
  gap: 0.5rem;
  display: flex;
  flex-flow: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 0px;
  margin: 0px;
  padding: 0px;
  width: auto;
  position: relative;
  cursor: unset;
  opacity: 1;
}

.languages {
  color: rgb(76, 76, 76);
  font-size: 0.8rem;
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.session-modes {
  color: rgb(76, 76, 76);
  font-size: 0.8rem;
}

.city {
  color: rgb(231, 105, 67);
  font-size: 0.8rem;
}

.css-o5srbh-MuiPaper-root-MuiAppBar-root,
.css-1we96i5-MuiPaper-root-MuiAppBar-root,
.css-1se5fxw,
.css-8w7ls2,
.css-1ub64jh-MuiPaper-root-MuiAppBar-root {
  background-color: #ddd !important;
  color: #4004a4 !important;
}

@media screen and (min-width: 375px) and (max-width: 667px) {
  .profile-view {
    font-weight: 550;
    font-size: 0.45rem;
    padding-top: 8px;
  }

  .languages {
    white-space: break;
  }
}

.MuiCardActions-root,
.MuiCardHeader-root {
  background-color: #DCDCDC !important;
}

.MuiFormLabel-asterisk {
  color: rgb(222 55 48) !important;
}

.MuiInputLabel-root {
  padding: 5px;
}