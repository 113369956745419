.footer-action {
  background: white;
  position: fixed;
  bottom: 0;
  height: 10%;
  width: 100%;
  padding-top: 8px;
}
.footer-buttons {
  position: fixed;
  right: 0;
  padding-right: 20px;
}
.box {
  width: 20px;
  height: 20px;
}
.color-container {
  display: flex;
}
.color-palette-title {
  margin-left: 16px;
}
.holiday {
  background-color: #f6e4e0;
  border: 1px solid rgb(209, 68, 38);
  background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 4px,
      transparent 1px,
      #e8b2ac 7px
    ),
    linear-gradient(to bottom, transparent, transparent);
}

.available-slot {
  background-color: rgb(167, 219, 201);
}

.not-available-slot {
  background-color: #debdb6;
}
.booked-slot {
  background-color: #3174ad;
}
.expired-date {
  background-color: #f57369;
  border: "solid 1px #FFF";
}
