.title-container {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 24px;
}
.no-appointments {
  height: 250px;
  justify-content: center;
  display: flex !important;
  align-items: center;
}
.MuiTableSortLabel-root,
.MuiTableSortLabel-icon {
  color: white !important;
}
